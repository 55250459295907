import { Auth } from 'aws-amplify';

async function uploadLogFile(file) {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    console.log('Cognito User:', cognitoUser);

    // Access the 'sub' attribute for the user ID
    const userId = cognitoUser.attributes.sub;

    console.log('UserID:', userId);

    const newFilename = `${cognitoUser.username}_${Date.now()}_${file.name}`;

    const fileWithNewName = new File([file], newFilename, {
        type: file.type,
    });

    const formData = new FormData();
    formData.append('file', fileWithNewName);

    const authKey = process.env.REACT_APP_AUTHKEY;

    try {
        const response = await fetch('https://winter-surf-82a0.deanlaughing.workers.dev/upload', {
            method: 'PUT',
            body: formData,
            mode: 'cors',
            headers: {
                'X-Custom-Auth-Key': authKey,
                'X-UserID': userId,
            },
        });

        console.log('Sending UserID:', userId);

        if (!response.ok) {
            const responseBody = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, body: ${responseBody}`);
        }

        const result = await response.text();
        return result;
    } catch (error) {
        console.error(`Error in uploadLogFile: ${error.message}`);
        throw error;
    }
}

export default uploadLogFile;

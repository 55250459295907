import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLogFile from './uploadlog';

Amplify.configure(awsconfig);

function App() {
  const [ipfsCID, setIpfsCID] = useState('');
  const [fetchedCIDs, setFetchedCIDs] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleFileSelection = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  async function handleFileUpload() {
    if (selectedFile) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userId = user.attributes.sub; // Cognito User ID

        const response = await uploadLogFile(selectedFile, userId);
        console.log(response); // Log the response
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      alert('Please select a file to upload.');
    }
  }

  const fetchCIDs = useCallback(async (retries = 3, delayTime = 1000) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;

      const response = await fetch(`https://ui-query.deanlaughing.workers.dev/action/find?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      });
      
      console.log('Response Status:', response.status);
      const responseData = await response.json();
      console.log('Response Data:', responseData); // Log the response data

      if (response.ok) {
        const itemsArray = Array.isArray(responseData.items) ? responseData.items : [];
        console.log('Retrieved CIDs and Scores:', itemsArray);
        setFetchedCIDs(itemsArray);
      } else {
        throw new Error(`Failed to retrieve CIDs. Reason: ${responseData.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Attempt to retrieve CIDs failed:", error);
      if (retries > 1) {
        await delay(delayTime);
        return fetchCIDs(retries - 1, delayTime);
      } else {
        alert('An error occurred while retrieving CIDs. Please try again.');
      }
    }
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserEmail(user.attributes.email);
        await fetchCIDs();
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchUserData();
  }, [fetchCIDs]);

  const handleUpload = async () => {
    if (!ipfsCID) {
      alert('Please enter an IPFS CID.');
      return;
    }

    // Get the current authenticated user's attributes
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub; // Cognito User ID

      const response = await fetch('https://large-field-analyzer.deanlaughing.workers.dev/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userId, // Send the Cognito User ID as Authorization
        },
        body: JSON.stringify({ ipfsCID }),
        mode: 'cors',
      });

      const responseData = await response.json();

      if (response.ok) {
        alert('IPFS CID submitted successfully!');
        console.log('Server Response:', responseData);
      } else {
        console.error("Server Response:", responseData);
        alert(`Failed to submit IPFS CID. Reason: ${responseData.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("There was an error submitting the IPFS CID:", error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Large Field Analyzer</h1>
      </header>
      <Authenticator>
        {({ signOut }) => (
          <div className="container">
            <div className="welcome">
              <h2>Welcome, {userEmail}</h2>     
            </div>
      
            <div className="input-group">
              <input
                className="input-cid"
                type="text"
                value={ipfsCID}
                onChange={(e) => setIpfsCID(e.target.value)}
                placeholder="Enter IPFS CID"
              />
              <button className="btn-submit-ipfs" onClick={handleUpload}>Submit IPFS CID</button>
            </div>
            <div className="upload-section">
              <input type="file" onChange={handleFileSelection} />
              <button onClick={handleFileUpload}>Upload Log File</button>
            </div>
            <div className="cid-list">
              <ul>
                {fetchedCIDs.map((item, index) => (
                  <li key={index}>
                    <div className="cid-item">
                      <div className="cid">{item.ipfsCID}</div>
                      <div className="score">Analyzer Score: {item.cuckooScore}/10</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <button className="btn sign-out" onClick={signOut}>Sign out</button>
           </div>
        )}
    </Authenticator>
      <footer className="App-footer">
        <p>Thank you for using Leto</p>
      </footer>
    </div>
  );
}  

export default App;